import { Card, Layout, Menu } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <Layout className={styles.container}>
      <Layout.Sider
        collapsedWidth={0}
        collapsible
        theme="light"
        trigger={null}
        width={100}
      >
        <LeftCondition store={store}/>
      </Layout.Sider>
      <Layout.Content>
        <Card size="small">
          <NormalProgrammeComponent store={store.normalProgramme}/>
        </Card>
        <div className={styles.tableWrapper}>
          <MainSubStructure store={store.mainSubStructureModel}/>
        </div>
      </Layout.Content>
    </Layout>
  );
}

const LeftCondition = observer(({
  store: {
    containerTypeStore: {
      selectedKeys,
      onSelect,
      data,
    },
  },
}: { store: Store; }) => {
  return (
    <Menu
      items={data.map((item) => ({
        key: item.value,
        label: item.label,
      }))}
      mode="inline"
      onSelect={onSelect}
      selectedKeys={selectedKeys}
    />
  );
});

