import { Form, InputNumber, Modal } from 'antd';
import React from 'react';
import type { MainItem } from './types';

interface Params {
  count?: number;
  number?: number;
}

interface AddAndEditModalProps {
  title: string;
  onCancel: () => void;
  callback: (params: Params) => Promise<unknown>;
  mainItem?: MainItem;
}

export function AddAndEditModal({
  onCancel,
  callback,
  mainItem,
  title,
}: AddAndEditModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: Params; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      open
      title={title}
      width={480}
    >
      <Form
        form={form}
        initialValues={{ number: mainItem?.number }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        {
          mainItem ? null : (
            <Form.Item
              label="容器数量"
              name="count"
              rules={[
                {
                  required: true,
                  message: '请输入容器数量',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最少为1',
                },
              ]}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入容器数量"
                style={{ width: 150 }}
              />
            </Form.Item>
          )
        }
        <Form.Item
          label="容器容量"
          name="number"
        >
          <InputNumber
            max={999999}
            min={0}
            placeholder="请输入容器容量"
            style={{ width: 150 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
