import { action, observable } from 'mobx';
import React from 'react';
import type { MainItem } from './types';
import { Button, message } from 'antd';
import type { BaseData, PaginationData, ValueAndLabelData } from 'egenie-utils';
import { MainSubStructureModel, NormalProgramme, request, Permission } from 'egenie-utils';
import type { DictData } from 'egenie-common';
import { destroyAllModal, renderModal, objToDict } from 'egenie-common';
import { ENUM_CONTAINER_TYPE } from './constants';
import { AddAndEditModal } from './addAndEditModal';

export class Store {
  constructor() {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.normalProgramme.filterItems.connect(this.containerTypeStore);
  }

  @action public handleAddContainer = (): void => {
    renderModal(
      <AddAndEditModal
        callback={((params) => {
          return request({
            url: '/api/cloud/baseinfo/rest/container/batchCreate',
            method: 'post',
            data: {
              ...params,
              ...this.containerTypeStore.toParams(),
            },
          })
            .then(() => {
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onQuery();
              message.success('新建成功');
              destroyAllModal();
            });
        })}
        onCancel={destroyAllModal}
        title="新建容器"
      />
    );
  };

  @action public handleEditContainer = (item: MainItem): void => {
    renderModal(
      <AddAndEditModal
        callback={((params) => {
          return request({
            url: '/api/cloud/baseinfo/rest/container/update',
            method: 'post',
            data: {
              containerId: item.containerId,
              number: params.number,
            },
          })
            .then(() => {
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onRefresh();
              message.success('编辑成功');
              destroyAllModal();
            });
        })}
        mainItem={item}
        onCancel={destroyAllModal}
        title="编辑容器"
      />
    );
  };

  public containerTypeStore: ContainerTypeStore = new ContainerTypeStore(this);

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        permissionId: '60201_1',
        text: '新建',
        handleClick: () => this.handleAddContainer(),
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ row }) => {
            return (
              <Permission permissionId="60201_44">
                <Button
                  onClick={() => this.handleEditContainer(row)}
                  size="small"
                  type="link"
                >
                  编辑
                </Button>
              </Permission>
            );
          },
        },
        {
          key: 'containerNo',
          name: '容器号',
          width: 200,
          sortable: true,
        },
        {
          key: 'typeName',
          name: '容器类型',
          width: 100,
        },
        {
          key: 'number',
          name: '容器容量',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'containerId',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showReset: false,
      showSelectedTotal: false,
      pageSize: 50,
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/baseinfo/rest/container/findPage',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'input',
        field: 'containerNo',
        label: '容器编码',
      },
    ],
    handleSearch: this.mainSubStructureModel.gridModel.onQuery,
  });
}

class ContainerTypeStore {
  constructor(private parent: Store) {
    this.getData();
  }

  @action public getData = () => {
    request<BaseData<DictData>>({ url: '/api/cloud/baseinfo/rest/container/type' })
      .then((info) => this.data = objToDict(info.data));
  };

  @observable public data: ValueAndLabelData = [];

  @observable public selectedKeys: string[] = [ENUM_CONTAINER_TYPE.pickCard.value];

  @action public onSelect = (item) => {
    this.selectedKeys = item.selectedKeys || [];
    this.parent.mainSubStructureModel.onQuery();
  };

  public toParams = () => {
    return { type: this.selectedKeys.join('') };
  };
}
